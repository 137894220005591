import { configPrepare } from "@/helpers/vue-good-table"

const i18nPrefix = "ota_management.plan_list.form.publish_settings.price_list"
const defaults = {
  thClass: "table-th fee",
  tdClass: "table-td fee",
  sortable: false
}

const fields = [
  {
    label: "",
    field: "price_label",
    thClass: "table-th price-label",
    tdClass: "table-td price-label"
  },
  {
    label: `${i18nPrefix}.after_markup_yen`,
    field: "after_markup_yen"
  },
  {
    label: `${i18nPrefix}.default_fee`,
    field: "default_fee"
  }
]

export const columns = configPrepare(fields, { i18nPrefix, defaults })
